import { observer } from "mobx-react-lite";
import { ComponentPropsWithoutRef } from "react";
import { CEXAbilities, CEXRoutes } from "src/app/constants";
import { BotActionButton } from "src/components/AllBots/Bots/shared/BotActionButton";
import {
  getBotLink,
  getPartySectionLink,
} from "src/components/NavigationMenu/MenuContent/shared/MenuList/utils";
import { PermissionWrapper } from "src/components/shared/PermissionWrapper";
import { getDowntime } from "src/helpers/dateUtils";
import { useBgColorStatus, useColorStatus } from "src/helpers/getStatus/getStatusColor";
import Icons from "src/icons/Icons";
import { ICEXBot } from "src/modules/bots";
import { BotLinkButton } from "../../shared/BotLinkButton";
import { BotMark } from "../../shared/BotMark";
import { BotPairButton } from "../../shared/BotPairButton";
import {
  BotContainer,
  Comment,
  Exchange,
  Frame,
  IconWrapper,
  Line,
  LockTime,
  LockTimeText,
  Message,
  Name,
  Time,
} from "../../shared/shared";
import { IBotCredential, copyBotCredential, getFormattedTimeNotWork } from "../../shared/utils";
import * as styles from "./style";

export interface CEXBotActions {
  start: () => void;
  stop: () => void;
}

interface CEXBotOwnProps extends ICEXBot {
  favorite: boolean;
  toggleIsWatched: Function;
  isWatched: boolean;
  market: string;
  pair: string;
  actions?: CEXBotActions;
}

export interface CEXBotProps
  extends CEXBotOwnProps,
    Omit<ComponentPropsWithoutRef<"div">, keyof CEXBotOwnProps> {
  afterContent?: React.ReactNode;
}

export const CEXBot = observer(
  ({
    favorite,
    toggleIsWatched,
    isWatched,
    status,
    timeDontWork,
    modifyVolume,
    link,
    exchange,
    side,
    message,
    diff,
    modifyTime,
    pair,
    party = "",
    bot_uuid,
    market,
    name,
    actions,
    afterContent,
    ...props
  }: CEXBotProps) => {
    const { base, quote, updated_at, created_at, ...containerProps } = props;

    const botColorStatus = useColorStatus();

    const botBgColorStatus = useBgColorStatus();

    const timeNotWork = getFormattedTimeNotWork(timeDontWork);

    const ActionButton = <BotActionButton status={status} actions={actions} />;

    const botLinkParams = {
      party,
      uuid: bot_uuid,
    };

    const dashboardLink = getPartySectionLink({ party, partySection: "Dashboard" });
    const exchangeLink = getBotLink({
      botType: "CEX",
      botTab: CEXRoutes.Exchange,
      ...botLinkParams,
    });

    const credential: IBotCredential = {
      market,
      name,
      UUID: bot_uuid,
      link: exchangeLink,
    };

    return (
      <BotContainer {...containerProps}>
        {favorite ? (
          <styles.Favorites>
            <IconWrapper onClick={() => toggleIsWatched(bot_uuid)}>
              {isWatched ? Icons.startStatusTrue() : Icons.startStatusFalse()}
            </IconWrapper>
          </styles.Favorites>
        ) : null}

        <Frame color={botColorStatus(status, timeDontWork)}>
          <styles.Content style={{ backgroundColor: botBgColorStatus(status, timeDontWork) }}>
            <styles.CellContent
              style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                paddingTop: "18px",
                paddingLeft: "20px",
                gap: "5px",
              }}
            >
              {bot_uuid ? (
                <BotPairButton to={dashboardLink} color={botColorStatus(status, timeDontWork)}>
                  {pair?.split("_").join(" _ ")}
                </BotPairButton>
              ) : null}

              <Name>{name}</Name>
            </styles.CellContent>

            <styles.CellContent>
              <Exchange href={link || "#"} target="_blank">
                {exchange}
              </Exchange>
            </styles.CellContent>

            <styles.CellContent
              style={{
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Time>{timeNotWork}</Time>
              {modifyVolume && getDowntime(modifyTime) ? (
                <LockTime style={{ marginTop: "5px" }}>
                  <LockTimeText>
                    {Icons.lock()} {modifyVolume && getDowntime(modifyTime)}
                  </LockTimeText>
                </LockTime>
              ) : null}
            </styles.CellContent>

            <styles.CellContent>
              {bot_uuid ? (
                <BotLinkButton to={exchangeLink} color={botColorStatus(status, timeDontWork)}>
                  {side}
                </BotLinkButton>
              ) : null}
            </styles.CellContent>

            <styles.CellContent style={{ padding: "5px 7px 5px 10px" }}>
              {message ? <Message>{message}</Message> : <Line />}
            </styles.CellContent>

            <styles.CellContent>{diff ? <Comment>{diff}</Comment> : <Line />}</styles.CellContent>

            <styles.CellContent>
              <styles.Wrapper>
                {ActionButton ? (
                  <PermissionWrapper abilityName={CEXAbilities.BotManage}>
                    {ActionButton}
                  </PermissionWrapper>
                ) : null}

                <BotMark
                  onClick={() => copyBotCredential(credential)}
                  color={botColorStatus(status, timeDontWork)}
                />
              </styles.Wrapper>
            </styles.CellContent>

            {afterContent && <styles.CellContent>{afterContent}</styles.CellContent>}
          </styles.Content>
        </Frame>
      </BotContainer>
    );
  }
);
